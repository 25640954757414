import "styles/pages/page-party.scss";
import React from "react";
import Layout from "components/Layout";
import Seo from "components/Seo";
import SubpageHeader from "components/SubpageHeader";
import ImageWithText from "../components/ImageWithText";
import Breadcrumbs from "../components/Breadcrumbs";

const PageParty = ({ pageContext }) => {
	const pageParty = pageContext.pageContent;
	return (
		<Layout headerColor="#6AD044">
			<Seo
				title={
					pageContext.seo.title
						? pageContext.seo.title
						: pageContext.title
				}
				description={
					pageContext.seo.metaDesc && pageContext.seo.metaDesc
				}
			/>
			<SubpageHeader
				image={require("assets/images/party-header-bg.jpg").default}
			/>
			<Breadcrumbs currentLocationLabel="Imprezy i festiwale" />
			<section className="party-important">
				<div className="party-important__main-wrapper">
					<div className="container">
						<h2 className="section-title">
							{pageParty.partyMainTitle}
						</h2>
						<div className="row">
							<div className="col-md-5 offset-md-1">
								<p className="party-important__text">
									{pageParty.partyMainDesc}
								</p>
							</div>
						</div>
						<div className="row justify-content-center">
							{pageParty.partyImagesWithDesc?.map(
								(node, index) => {
									return (
										<div
											className="col-xl-5 col-md-6"
											key={index}
										>
											<ImageWithText
												image={
													node.partyImage?.sourceUrl
												}
												title={node.partyImageTitle}
												text={node.partyImageDesc}
												marginBottom={150}
											/>
										</div>
									);
								}
							)}
							{pageParty.festivalsImagesWithDesc?.map(
								(node, index) => {
									return (
										<div
											className="col-xl-5 col-md-6 item "
											key={index}
										>
											<ImageWithText
												image={
													node.festivalsImage
														?.sourceUrl
												}
												title={node.festivalsTitle}
												text={node.festivalsDesc}
												marginBottom={150}
											/>
										</div>
									);
								}
							)}
						</div>
					</div>
				</div>
			</section>
		</Layout>
	);
};

export default PageParty;
